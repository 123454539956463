@import "../../styles/utils";

.user {
  &-avatar {
    .user-details {
      &__profile-pic {
        margin-top: 0;
      }
    }
  }

  &__manage {
    &-header {
      font-weight: $font-weight-700;
      margin-bottom: 10px;
    }

    &-description {
      font-weight: $font-weight-400;
      margin-bottom: 10px;
    }
  }

  &__list {
    &--rtabs {
      padding: 20px;
    }
  }

  &__phone {
    &-wrapper {
      margin-top: 20px;
    }
  }

  &__edit {
    &--form-content {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }

    &--submit {
      margin-top: 20px;
    }
  }

  &__credential-form {
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &--card {
      margin-top: 20px;
    }

    &-email {
      &-explanation {
        color: $cool-gray-15;
        font-size: 12px;
        font-weight: 200;
      }
    }

    &--confirm-password {
      padding-left: 20px !important;
    }
  }

  &-two-factor-configuration {
    .input {
      width: 50%;
    }
    
    &__status {
      margin-top: 40px;
    }

    &__scan-code {
      display: flex;
      flex-direction: column;

      &-canvas {
        align-self: center;
      }
    }

    &__action {
      button:first-of-type {
        margin-right: 8px;
      }
    }

    &--input {
      margin-top: 10px;
    }

    &--top-margin {
      margin-top: 20px;
    }
  }

  &__form {
    &--title {
      color: $blueish-black;
      font-size: $font-24;
      font-weight: $font-weight-700;

      &-two-factor {
        margin-left: 15px;
      }
    }
  }

  &__general {
    &-form {
      &--content {
        column-gap: 20px;

        &-left,
        &-right {
          display: flex;
          flex-basis: 50%;
          flex-direction: column;
          row-gap: 20px;
        }
      }

      &--card {
        border-radius: 5px;
        box-shadow: $form-card-shadow;
        margin-top: 20px;
      }

      &--footer {
        margin-top: 20px;

        &__cancel-btn {
          margin-left: 10px;
        }
      }
    }

    &-icon {
      border-radius: 50%;
      bottom: 36px;
      position: relative;
      right: 36px;

      .icon {
        &__content {
          background-color: rgba($blueish-black, 0.16);
        }

        svg {
          fill: $dark-blue;
        }
      }
    }

    &-image {
      &__wrapper {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
      }
    }
  }

  &__email {
    &-header {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
    }

    &-content {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &--form {
      .rcl-checkbox {
        &__check,
        &--checked {
          &:hover {
            &::before {
              content: none;
            }
          }

          &::before {
            content: none;
          }
        }
      }
    }
  }

  &__phone,
  &__email {
    &--list {
      display: flex;
      flex-direction: column;
      row-gap: 20px;

      .card {
        box-shadow: $form-card-shadow;
      }
    }
  }

  &__invite {
    &-role {
      .user__invite--form__wrapper {
        width: 48%;
      }
    }

    &-card {
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      max-width: 640px;
    }

    &--title {
      align-items: center;
      display: flex;
      justify-content: space-between;
      width: 100%;

      .icon-button {
        margin-right: 0;
        padding-right: 0;
      }
    }

    &--form {
      align-items: center;
      display: flex;
      justify-content: space-between;
      min-width: 520px;

      &__checkbox {
        margin-bottom: 10px;
      }

      &__field-error {
        color: $normal-red;
        font-size: 12px;
        margin-top: 5px;
        margin-left: 5px;
        min-height: 20px;
        text-transform: capitalize;
      }
    }

    &--modal {
      color: $modal-text-color;

      .modal {
        &__container {
          max-width: 400px;
        }

        &__footer {
          margin-top: 0;
        }
      }

      &-loading,
      &-success,
      &-failure {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
      }

      &-loading {
        &-message {
          font-size: 16px;
          font-weight: 400;
          margin-top: 20px;
        }
      }

      &-success {
        &-message {
          text-align: center;

          :first-child {
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
          }

          :last-child {
            font-size: 14px;
            font-weight: 400;
            margin-top: 12px;
          }
        }
      }

      &-success-icon {
        .icon__content {
          background-color: $pastal-green;
        }

        svg {
          fill: $white;
        }
      }

      &-failure {
        &-message {
          text-align: center;

          :first-child {
            font-size: 16px;
            font-weight: 700;
            margin-top: 20px;
          }

          :nth-child(2),
          :last-child {
            font-size: 14px;
            font-weight: 400;
            margin-top: 12px;
          }
        }

        &-icon {
          svg {
            fill: $normal-red;
          }
        }
      }
    }

    &--footer {
      display: flex;
      justify-content: flex-start;
      margin-bottom: 20px;
      margin-top: 5px;

      &__cancel-btn {
        margin-left: 10px;
      }
    }

    &--horizontal-row {
      margin-top: 20px;
      opacity: 0.3;
    }

    &--button {
      border-radius: 4px;
      margin-left: auto;
    }
  }

  &__action {
    cursor: pointer;

    &--show,
    &--edit {
      .icon {
        svg {
          fill: $dark-blue;
          font-size: 1rem;
        }
      }
    }

    &__tooltip {
      font-weight: 400;
    }
  }

  &__loader {
    &--wrapper {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &__education {
    &--container {
      .card {
        box-shadow: $form-card-shadow;
      }
    }

    &--header {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &-row {
      display: flex;
      justify-content: space-between;
    }

    &--card {
      display: flex;

      &__actions {
        display: flex;
        justify-content: flex-end;
        margin-right: 20px;
        width: 100%;
      }

      &__diploma-icon {
        svg {
          fill: $dark-blue;
        }
      }

      &__icon {
        display: flex;
        justify-content: flex-end;
      }

      &__content {
        margin-top: 5px;
      }

      &__header {
        color: $medium-grey;
      }
    }

    &--form {
      &__file {
        margin-top: 20px;
      }
    }

    &--footer {
      margin-left: 15px;
      margin-top: 5px;
    }
    &-action {
      &--show {
        justify-content: center;
        padding: 0;
      }
    }

    &-table {
      &--row > td {
        padding: 20px;
      }
    }
  }

  &__list {
    &--header {
      align-items: center;
      color: $blueish-black;
      display: flex;
      justify-content: space-between;
    }

    .rtabs {
      &__nav {
        margin-top: 0;
      }
    }

    &--count {
      color: $blueish-black;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: -0.02em;
    }

    &--table {
      &-row-email {
        text-transform: lowercase;
      }

      &-avatar {
        .rcl-avatar {
          &-link {
            &__content {
              color: $blueish-black;
            }
          }
        }
      }

      .table {
        &__field {
          color: $blueish-black;
          font-size: 14px;
          font-weight: 400;
        }

        &__head {
          .table {
            &__field {
              font-size: 16px;
              font-weight: 700;
              letter-spacing: -0.02em;
            }
          }
        }
      }
    }

    &--loader {
      align-items: center;
      display: flex;
      justify-content: center;
    }
  }

  &__rtabs {
    padding: 0 20px 20px;

    .rcl-tooltip__content {
      font-weight: $bolder-font-weight;
    }

    .rtabs {
      &__nav {
        &-item {
          font-size: 14px;

          &--active {
            opacity: 1;
          }
        }
      }

      &__pane {
        padding: 20px 0;
      }
    }
  }

  &__head {
    align-items: center;
    background-color: rgba($blueish-black, 0.08);
    display: flex;
    height: 80px;

    &--image {
      margin-left: 20px;
    }

    &--title {
      margin-left: 10px;

      &__main {
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 10px;
      }

      &__subtitle {
        font-size: 14px;
        font-weight: $font-weight-400;
      }
    }

    &--address {
      margin: auto;

      &__with-icon {
        display: flex;
      }

      &__icon {
        margin-right: 12px;
        .icon {
          svg {
            fill: $black;
          }
        }
      }

      &__info {
        font-size: 12px;
      }
    }
    &--view {
      cursor: pointer;
      font-size: 12px;
      margin-right: 20px;
    }
  }

  &-details {
    &__spinner {
      align-items: center;
      display: flex;
      justify-content: center;
    }

    &__action {
      &--show {
        .icon {
          svg {
            fill: $dark-blue;
          }
        }
      }

      &--edit {
        .icon {
          svg {
            fill: $dark-blue;
          }
        }
      }
    }

    &__modal {
      &--title {
        &__name {
          font-size: 20px;
          font-weight: 900;
          margin-top: 20px;
        }
      }

      .modal {
        &__title {
          align-items: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

        &__content {
          text-align: center;
        }

        &__container {
          align-items: center;
          display: flex;
          flex-direction: column;
          height: 250px;
          justify-content: center;
          max-width: 396px;
        }

        .icon {
          &__content {
            padding: 28px;
          }
        }
      }
    }

    &__report-icon {
      cursor: pointer;
      justify-content: center;

      path {
        fill: $dark-blue;
      }
    }

    &__wrapper {
      .card {
        &--v2 {
          &__wrapper {
            text-align: center;
          }
        }
      }
    }

    &__head {
      .card {
        &__body-section {
          align-items: center;
          display: flex;
          justify-content: space-between;
        }
      }
    }

    &__edit {
      margin-left: 10px;
    }

    &__gastronomy {
      margin-left: 20px;
    }

    &__items {
      display: flex;
    }

    &__content {
      display: flex;
    }
    &__profile-pic {
      border-radius: 100%;
      margin-top: 20px;
    }

    &__name {
      font-size: 20px;
      font-weight: 900;
      margin-bottom: 30px;
    }
    &__phone,
    &__email,
    &__dob-label,
    &__language,
    &__organisation,
    &__assigned-organisation {
      font-size: 12px;
      font-weight: 400;
      margin-right: 14px;

      &--icon {
        margin-right: 12px;

        svg {
          fill: $dark-blue;
        }
      }

      &--list {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        .organization-name ~ .organization-name:not(:empty):before {
          content: ", ";
        }
      }
    }

    &__email {
      &--wrapper {
        margin-top: 24px;
      }
    }
    &__language {
      &--wrapper {
        margin-top: 30px;
      }

      &--list {
        font-size: 14px;

        &__wrapper {
          margin-left: 25px;
          margin-top: 8px;
        }
      }
    }

    &__organisation {
      &--wrapper {
        margin-top: 30px;
      }

      &--list {
        font-size: 14px;

        &__wrapper {
          margin-left: 25px;
          margin-top: 8px;
        }
      }
    }

    &__dob-label {
      &--wrapper {
        margin-top: 32px;
      }
    }

    &__dob {
      font-size: 14px;
      font-weight: 400;
      margin-left: 25px;

      &--wrapper {
        margin-top: 10px;
      }
    }

    &__assigned-organisation {
      &--wrapper {
        margin-top: 20px;
      }

      &--list {
        font-size: 14px;

        &__wrapper {
          margin-left: 25px;
          margin-top: 8px;
        }
      }
    }

    &__view {
      color: $info;
      cursor: pointer;

      &--wrapper {
        font-size: 10px;
        margin-left: 24px;
        margin-top: 8px;
      }
    }

    &__avatar {
      padding-bottom: 10px;
      padding-top: 20px;
    }

    &__assignment-list {
      padding-top: 20px;
    }
  }

  &__list {
    &-stacked-items {
      color: $light-violet;
    }
  }

  &__assignment,
  &__education {
    &--container {
      display: flex;
      flex-direction: column;
      row-gap: 20px;
    }
  }

  &__search {
    border: 1px solid rgba($blueish-black, 0.16);
    border-radius: 4px;
    width: 250px;
  }
}
