$secondary-color: #0e214d;

// new
// Latest colors from styleguide
$cool-gray-100: #f8f9fd;
$cool-gray-99: #fcfcfc;
$cool-gray-98: #f9f9f9;
$cool-gray-97: #f6f6f6;
$cool-gray-96: #f3f3f3;
$cool-gray-95: #f1f1f1;
$cool-gray-94: #eeeeee;
$cool-gray-93: #ebebeb;
$cool-gray-92: #e8e9e8;
$cool-gray-91: #e4e4e4;
$cool-gray-90: #e2e2e2;
$cool-gray-89: #fefefc;
$cool-gray-88: #dddddd;
$cool-gray-87: #eeeeee;
$cool-gray-86: #dddddd;
$cool-gray-85: #d4d4d4;
$cool-gray-84: #dbdbdb;
$cool-gray-82: #cbcbcb;
$cool-gray-80: #c6c6c6;
$cool-gray-75: #b9b9b9;
$cool-gray-70: #ababab;
$cool-gray-67: #a0a0a0;
$cool-gray-66: #999999;
$cool-gray-65: #959595;
$cool-gray-60: #919191;
$cool-gray-50: #777777;
$cool-gray-48: #757575;
$cool-gray-45: #707070;
$cool-gray-40: #5e5e5e;
$cool-gray-35: #525252;
$cool-gray-33: #505050;
$cool-gray-30: #474747;
$cool-gray-29: #444444;
$cool-gray-25: #3b3b3b;
$cool-gray-24: #393939;
$cool-gray-20: #303030;
$cool-gray-20-12: rgba($cool-gray-20, 0.12); //#30303012;
$cool-gray-20-65: rgba($cool-gray-20, 0.65);
$cool-gray-18: #2c2c2c;
$cool-gray-15: #262626;
$cool-gray-12: #1d1d1d;
$cool-gray-10: #1b1b1b;
$cool-gray-5: #1a1a1a;
$cool-gray-3: #191919;

$medium-carmine: #b44141;
$cool-gray-shadow: rgba($cool-gray-70, 0.117647);
$mystified-earth: #c44847;
$approaching-pistachio: #00b575;
$attracting-peach: #ff605d;
$precocious-azure: #2a496f;
$anchor-blue: #2877e4;
$spare-butter: #f9b93c;
$florid-tar: #182146;
$posh-sorrel: #969aab;
$accent: $medium-carmine;
$carnation: #f24f4e;
$mercury: #e5e5e5;
$alto: #e0e0e0;
$secondary-accent: #1c355d;
$tertiary-accent: #4b73a7;
$wedgewood: #5b7faf;
$lynch: #76869e;
$san-juan: #2e4569;
$ghost-gray: #c5c7d0;
$disabled: #969aab;
$disabled-light: #d9d9d9;
$cornflower-blue: #4287ef;
$hawkes-blue: #f1f6fe;

// Viewport declarations for responsive layouts

$large-desktop: 1200px; // large desktops
$desktop-tab: 991px; // desktops, landscape and portrait tabs
$tab-mobile: 767px; // portrait tabs and landscape mobiles
$mobile: 480px; // landscape and portrait mobiles
$small-mobile: 360px; // small landscape and portrait mobiles

// Variables for new grid system

$gutter: 1rem;
$base-width: 117rem;
$column-width: calc(100% / 12);
$grid-count: 12;

// depricated variables
$mojo: rgb(199, 61, 65);
$red: $mojo;
$light-red: lighten($red, 30);
$error-color: lighten($red, 30);
$action-color-hover: $red;
$secondary-action-color: $red;
$dark-red: rgb(220, 0, 0);

$silver: rgb(204, 204, 204);
$gray: $silver;
$medium-gray: $silver;
$light-gray: lighten($silver, 15);
$dark-gray: darken($silver, 30);
$gray1: #ececec;
$gray2: #f7f7f7;
$mortar: rgb(87, 87, 87);
$darker-gray: rgb(15, 13, 13);
$darkest-gray: #474747;
$cloud-gray: #eaebef;
$cream: rgb(245, 245, 245);
$off-white: #f9f9f9;
$bluish-gray: #dee1e6;
$light-white-shade: rgba(255, 255, 255, 0.2);

$white: rgb(255, 255, 255);
$white-16: rgba($white, 0.16);
$main-background-color: $white;
$dark-off-white: rgb(246, 246, 246);
$background-accent-color: $dark-off-white;

$pickled-bluewood: rgb(43, 61, 79);
$blue: $pickled-bluewood;
$action-color: $blue;
$notice-color: lighten($blue, 50);
$light-blue: lighten($blue, 25);
$alice-blue: rgb(242, 250, 255);
$mariner: rgb(36, 117, 219);
$dodger-blue: rgb(56, 145, 246);

$mine-shaft: rgb(48, 48, 48);
$black: #000000;
$black-70: rgba($black, 0.7);
$black-42: rgba($black, 0.42);
$black-28: rgba($black, 0.28);
$black-14: rgba($black, 0.14);
$base-font-color: $black;

$green: #008000;
$dark-green: darken($green, 30);
$light-green: lighten($green, 10);
$success-color: $green;

$orange: #ffa500;

$bright-leaf: #5abf5b;
$comestic-chartreuse: #c2c759;
$committed-tawny: #71a667;
$fantastic-royal: #43c3b3;
$lofty-ashen: #5243c7;
$losing-rust: #bf4777;
$outer-bronze: #c28539;
$predictable-shark: #8f57a6;
$shiny-cobalt: #4c9ef2;
$unruly-chartreuse: #f2d464;
$needful-mousy: #969aab;

$subordinate-snow: #eff1f6;
$considerate-royal: #6687b5;
$medium-shadow: rgba(75, 115, 167, 0.24);
$default-shadow: rgba(75, 115, 167, 0.16);
$checkbox-hover-shadow: rgba(136, 140, 157, 0.16);
$default-border: rgba(227, 232, 245, 1);
$florid-tar: #182146;

//Shades
$dark-shade: rgba(0, 0, 0, 0.2);
$light-shade: rgba(0, 0, 0, 0.1);
$grey-shade: rgba(0, 0, 0, 0.08);

$subordinate-snow: #eff1f6;
$considerate-royal: #6687b5;
$florid-tar: #182146;

// Font Sizes
$base-font-size: 1.6rem;
$h1-font-size: $base-font-size * 2.25;
$h2-font-size: $base-font-size * 1.75;
$h3-font-size: $base-font-size * 1.5;
$h4-font-size: $base-font-size * 1.25;
$h5-font-size: $base-font-size * 1.15;
$h6-font-size: $base-font-size;

// Font Weights
$light-font-weight: 200;
$normal-font-weight: 300;
$bolder-font-weight: 400;
$bold-font-weight: 500;
$boldest-font-weight: 600;
$heading-font-weight: $normal-font-weight;

// Line height
$base-line-height: 1.5;
$heading-line-height: 1.2;

// Other Sizes
$navbar-min-height: 40px;
$base-border-radius: 0;
$base-spacing: $base-line-height * 1em;
$small-spacing: $base-spacing / 2;
$base-z-index: 0;
$hero-tab-height: 280px;

$yellow: #fff6bf;
$alert-color: $yellow;

$green-sea: #16a085;
$jeans: #30649c;
$tan-hide: rgb(245, 126, 95);

// Border
$base-border-color: $light-gray;
$base-border: 1px solid $base-border-color;
$alert-list-border: 1px solid rgba(0, 57, 63, 0.08);

// Background Colors
$secondary-background-color: tint($base-border-color, 75%);

// Forms
$form-box-border-color: $dark-gray;
$form-box-shadow: inset 0 1px 3px rgba($black, 0.06);
$form-box-shadow-focus: $form-box-shadow,
  0 0 5px adjust-color($dark-gray, $lightness: -5%, $alpha: -0.3);
$form-card-shadow: 0 1px 3px rgba(87, 7, 51, 0.12);

// Animations
$base-duration: 150ms;
$base-timing: ease;

$base-spacing: 1em;

$base-border-radius: 0;
$base-background-color: $light-gray;

$mat-blue: #1d72de;
$dark-mat-blue: #0066d0;
$light-alice-blue: #f2f9ff;
$light-skyblue: #76afff;

$base-line-height: 1.5;

$base-spacing: $base-line-height * 1em;

$base-link-color: #4286f4; // I can has style

//variables from META
$downhill-violet: #2877e4;
$evangelical-ivory: #eceef3;
$merciless-bronze: #44474d;
$bogus-licorice: #333333;
$comparative-snow: #e3e3e3;
$astronomical-silver: #f9f9f9;
$shining-steel: #5bc0de;
$smooth-satin: #f5f5f5;
$disturbed-violet: #428bca;
$historic-milky: #f8f8f8;
$forward-satin: #e7e7e7;
$awake-amber: #555555;
$bitter-milky: #eeeeee;
$ready-cinnamon: #777777;
$fashionable-sapphire: #09d3ac;
$earthly-royal: #3ab974;
$prime-marble: #d0d0d0;
$rightful-chocolate: #e04e3f;
$error-background: rgba(249, 185, 60, 0.6);
$error-text: rgba(0, 0, 0, 0.7);
$casual-azure: #394160;
$rosy-chicory: #171717;
$relentless-platinum: #f7f8ff;
$dynastic-tar: #182146;
$leaky-rust: #888c9d;
$leaky-rust-light: #eeeff1;

//Color-Palette
$primary-accent: #c44a47;
$secondary-accent: #1c355d;
$secondary-accent-light: #475b7d;
$tertiary-accent-light: #e2e8f1;
$default: $white;
$background: $relentless-platinum;
$blueish-black: #00393f;
$blueish-black-8: rgba($blueish-black, 0.08);
$blueish-black-16: rgba($blueish-black, 0.16);
$blueish-black-24: rgba($blueish-black, 0.24);
$blueish-black-40: rgba($blueish-black, 0.4);
$primary-text: $blueish-black;
$primary-text-70: rgba($primary-text, 0.7);
$primary-text-light: rgba($primary-text, 0.08);
$secondary-text: $leaky-rust;
$danger: #ff5555;
$danger-16: rgba($danger, 0.16);
$danger-light: #fce4e4;
$success: #52b987;
$success-16: rgba($success, 0.16);
$success-light: #e1f4eb;
$warning: #ffd938;
$warning-16: rgba($warning, 0.16);
$warning-light: #fdf3e0;
$warning-medium: #fef7da;
$warning-semi-dark: #856b05;
;

// Field Colors
$periwinkle-gray: #dbe2f1;
$santas-gray: #969aab;
$big-stone: #182146;
$polo-blue: #84a5d0;
$athens-gray: #edeef0;
$catskill-white: #f1f4f8;
$subordinate-snow: #eff1f6;
$considerate-royal: #6687b5;

//Font Sizes
$rcl-font-14: 14px;
$rcl-font-20: 20px;
$rcl-font-24: 24px;

// Tooltip configs in all the components
$input-tooltip-tip-offset: 0;

//New color
$warning-dark: #45300b;
$warning-dark-8: rgba($warning-dark, 0.08);
$warning-dark-16: rgba($warning-dark, 0.16);
$danger-dark: #450b0b;
$danger-dark-8: rgba($danger-dark, 0.08);
$success-dark: #0b4543;
$success-dark-8: rgba($success-dark, 0.08);
$green-cyan: #32b07e;
$sunset-orange: #ff4c50;
$yellow-orange: #ffac3f;

//Bio-suisse-color
$grey-white: #f5f5f5;
$greyish-white: #fbfbfb;
$medium-grey: #999999;

//Primary color
$green: #39a22b;
$dark-red: #d21e1f;

//Secondary color(Typography Mockups)

$dark-yellow-green: #acbb00;

//Secondary color(Elements)
$dark-green: #267b39;
$light-maroon: #8c0002;
$dark-blue: #383384;
$light-blue: #6a9bfa;
$sky-blue: #308ee5;
$light-lemon: #d6dfe0;

//System color
$pastal-green: #72b469;
$warning-yellow: #ffd938;
$light-violet: #8084fa;
$normal-red: #ed2424;
$info: #6a9bfa;

//Promotional color
$promotions-green: #dee555;

//Modal color
$modal-text-color: #102a43;

//font-weight
$font-weight-700: 700;
$font-weight-600: 600;
$font-weight-500: 500;
$font-weight-400: 400;
$font-weight-300: 300;
$font-weight-200: 200;

//font-size
$font-10: 10px;
$font-12: 12px;
$font-14: 14px;
$font-16: 16px;
$font-20: 20px;
$font-24: 24px;
