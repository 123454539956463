@import "../../styles/utils";

.welcome-page {
  &__form {
    &--content {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: flex-start;

      .user-two-factor-configuration {
        > span {
          display: block;
          font-size: 30px;
          margin-bottom: 40px;
          text-align: center;
          width: 100%;
        }

        .emphasis-tag {
          display: none;
        }

        &__warning {
          margin: 15px 0;
        }
      }
    }

    .rcl-popover__parent {
      bottom: 70px;
      display: block;
      float: right;
      position: relative;
    }
  }

  &__error-wrapper {
    height: 100%;
  }

  &__language {
    display: flex;
    margin-top: 20px;
  }

  &__image {
    height: 100vh;
    left: -70px;
    position: absolute;
    top: -60px;
    z-index: 1;
  }

  &__horizontal-line {
    margin-top: 40px;
    opacity: 0.3;
    width: 250px;
  }

  &__registration {
    margin-top: 20px;

    &--button {
      color: $dark-green;
      cursor: pointer;
      font-weight: 700;
    }
  }

  &__logo {
    margin-top: 100px;
  }

  &__title {
    color: $primary-text;
    margin: 70px 0 20px;
  }
}
